<template>
  <div id="app">
    <header class="app-title">
      <img id="app-icon" alt="app logo" src="./assets/icon256.png">
      <h1 id="app-name">AugCampos Bookmarks Cloud Admin</h1>
    </header>
    <div class="app-container">
      <Main msg=""/>
    </div>
    <footer class="app-footer">
      <mdb-container fluid>
        &copy; 2020 Copyright: <a href="https://augcampos.com"> AugCampos.Com </a>
      </mdb-container>
    </footer>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

header {
  display: inline-flex;
}

#app-icon {
  margin-top: 13px;
  height: 48px;
  width: 48px;
}

.app-container {
  border: darkcyan solid thin;
  margin-top: 10px;
}
footer, .app-footer {
  margin-top: 30px;
  font-size: x-small;
}
</style>
