<template>
  <div class="app-main">
    <h1>{{ msg }}</h1>


  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    msg: String
  }
}
</script>

<style scoped>

</style>
